<script>
import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page-header.vue";
import axios from "axios";
import {required} from "vuelidate/lib/validators";
import {errorCatcher} from "@/helpers/error-catcher";
import {swalHelper} from "@/helpers/swal-helper";

export default {
  components: {
    Layout,
    PageHeader
  },

  data() {
    return {
      submitted: false,

      table: {
        rows: 0,
        totalRows: 0,
        currentPage: 1,
        perPage: 100,
        pageOptions: [5, 10, 25, 50, 100],
        filter: null,
        filterOn: [],
        sortBy: "name",
        sortDesc: false,
        inputPage: "",
        items: []
      },

      bannedPhrase: {
        phrase: ""
      },

      showModal: false
    };
  },

  validations: {
    bannedPhrase: {
      phrase: { required },
    }
  },

  methods: {

    getItems() {
      return [
        {
          text: "ecat"
        },
        {
          text: this.$t('parsers.banned-phrases.title'),
          active: true
        }
      ]
    },

    getFields() {
      return [
        { key: "phrase", label: this.$t('parsers.banned-phrases.modal.phrase') },
        { key: "action", slot: true, label: this.$t('table.actions') }
      ]
    },

    async loadBannedPhrases() {
      try {
        let page = this.table.currentPage - 1;
        if (page > 0) {
          page = this.table.currentPage * this.table.perPage - this.table.perPage;
        }

        const {data} = await axios.get(`/parser/banned-phrase/pagination`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
          params: {
            page: page,
            size: this.table.perPage,
            filter: this.table.filter
          }
        });

        this.table.items = data.bannedPhrases
        this.table.totalRows = data.count
        this.table.rows = data.count
      } catch (error) {
        errorCatcher.catchErrors(error)
      }

      return this.table.items;
    },

    editBannedPhrase(bannedPhrase) {
      this.bannedPhrase = Object.assign({}, bannedPhrase);
      this.showModal = true
    },

    deletePhrase(bannedPhrase) {
      swalHelper.yesOrNoWithSubTitle(() => {
        axios.delete(`/parser/banned-phrase`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {"id": bannedPhrase.id}
        }).then(() => {
          this.$bvToast.toast(this.$t('parsers.banned-phrases.delete.success'), {
            title: this.$t('message.success'),
            variant: 'success',
            solid: true
          });

          this.$refs.table.refresh()
        }).catch((error) => {
          errorCatcher.catchErrors(error)
        })
      }, false)
    },

    async createOrEditBannedPhrase() {
      this.submitted = true;

      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      try {
        const json = JSON.stringify(this.bannedPhrase);

        await axios.put(`/parser/banned-phrase`, json, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        })

        this.$bvToast.toast(this.$t(this.bannedPhrase.id ? "parsers.banned-phrases.edit.success" : "parsers.banned-phrases.create.success"), {
          title: this.$t('message.success'),
          variant: 'success',
          solid: true
        });

        this.$refs.table.refresh()
        this.hideModal()
      } catch (error) {
        errorCatcher.catchErrors(error)
        this.submitted = false;
      }
    },

    hideModal() {
      this.submitted = false;
      this.showModal = false;
      this.bannedPhrase = {
        phrase: ""
      };
    }

  }

};
</script>

<template>
  <Layout>
    <PageHeader :title="$t('parsers.banned-phrases.title')" :items="getItems()" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div>
              <a class="btn btn-success mb-2 mx-2" @click="showModal = true">
                <i class="mdi mdi-plus mr-2"></i> {{ $t('parsers.banned-phrases.create.button') }}
              </a>
            </div>

            <ecat-table
                ref="table"
                :fields="getFields()"
                :items="loadBannedPhrases"
                :pagination-top="true"
                :table="table">
              <template v-slot:action="{ item }">
                <a @click="editBannedPhrase(item)" class="clickable-element mr-3 text-primary"><i class="mdi mdi-pencil font-size-18"></i></a>
                <a @click="deletePhrase(item)" class="clickable-element text-danger"><i class="mdi mdi-trash-can font-size-18"></i></a>
              </template>

            </ecat-table>
          </div>
        </div>
      </div>
    </div>

    <b-modal
        id="modal-1"
        :scrollable="true"
        v-model="showModal"
        :title="$t(bannedPhrase.id ? 'parsers.banned-phrases.edit.title' : 'parsers.banned-phrases.create.title')"
        title-class="font-18"
        hide-footer
        @hide="hideModal"
        @esc="hideModal">
      <div>
        <div class="form-group">
          <label for="phrase">{{  $t('parsers.banned-phrases.modal.phrase') }}</label>
          <input id="phrase" v-model="bannedPhrase.phrase" :class="{ 'is-invalid': submitted && $v.bannedPhrase.phrase.$error }" class="form-control" type="text" @keyup.enter="createOrEditBannedPhrase" />
          <div v-if="submitted && !$v.bannedPhrase.phrase.required" class="invalid-feedback">{{ $t('message.required') }}</div>
        </div>

        <div class="text-right">
          <ecat-button :callback="() => createOrEditBannedPhrase()" variant="success" :message="$t(bannedPhrase.id ? 'parsers.banned-phrases.edit.button' : 'parsers.banned-phrases.create.button')" />
          <b-button class="ml-1" variant="danger" @click="hideModal">{{  $t('message.cancel') }}</b-button>
        </div>
      </div>
    </b-modal>

  </Layout>
</template>